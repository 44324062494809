import '../styles/index.scss';

import "bootstrap/dist/js/bootstrap.bundle";

import Tab from 'bootstrap/js/dist/tab';

import './cookies.js';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}


// Trigger the service tabs in turn
const standardTimeout = 10000;
const clickTimeout = 30000;

const serviceTabs = document.querySelectorAll('a.circle');
window.currentTab = serviceTabs[0];
const showTab = (tab) => {
  var tabToShow = new Tab(tab);
  tabToShow.show();
};
const nextTab = (currentTab) => {
  let nextItem;
  let index = Array.from(serviceTabs).indexOf(currentTab);
  if (index >= 0 && index < serviceTabs.length - 1) {
    nextItem = serviceTabs[index + 1];
  } else {
    nextItem = serviceTabs[0];
  }
  return nextItem;
};
const showNextTab = () => {
    // If there is a tab selected, by the user, pause scrolling tabs
    // Otherwise, scroll to next.
    if (!window.hasOwnProperty('selectedTab')) {
      window.currentTab = nextTab(window.currentTab);
      showTab(window.currentTab);
      pauseTabRoll(window.currentTab, standardTimeout);
    } else {
      // Do nothing while the new tab is selected
    }
};

const triggerShowNextTab = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // If there's no interval already, set it
      if (!window.hasOwnProperty('triggerInterval')){
        // intersecting - setting interval
        window.triggerInterval = setInterval(showNextTab, 1000);
      } 
    } else {
      // not intersecting - unsetting interval
      clearInterval(window.triggerInterval);
      delete window.triggerInterval;
    }
  });
};

// Only show next tab when the section is in view
let observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.75
};

// Setting up intersection observer
const observer = new IntersectionObserver(triggerShowNextTab, observerOptions);
observer.observe(document.querySelector('#services'));

let pauseTimeout;
const pauseTabRoll = (tab, timeout) => {
  // set selectedTab to the one selected
  window.selectedTab = tab;

  // Set current tab to the one selected
  window.currentTab = window.selectedTab;

  // Clear any timeouts
  clearTimeout(pauseTimeout);

  // remove selectedTab after a while so that tabbing can continue
  pauseTimeout = setTimeout(() => {
    delete window.selectedTab;
  }, timeout);
};

// init pause for tab 0
pauseTabRoll(serviceTabs[0], standardTimeout);
// add click events for each tab
serviceTabs.forEach((tab) => {
  // pass click event target to pause function. Target gets the image, so need to select parent (picture) parent (a: link)
  tab.addEventListener("click", (event) => pauseTabRoll(event.target.parentElement.parentElement, clickTimeout));
});

// Smooth scrolling
function smoothScrollingTo(target) {
  document.querySelector(target).scrollIntoView({
    behavior: 'smooth'
  });
}
const anchors = document.querySelectorAll('a[href*=\\#]');
anchors.forEach((anchor) => {
  anchor.addEventListener("click", (event) => {
    event.preventDefault();
    smoothScrollingTo(event.target.hash);
  });
});
